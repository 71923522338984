// core
import React, {useEffect, useState, useRef, createRef} from "react"
import { navigate } from "gatsby";
import {useLocation} from '@reach/router';
import Tab from "react-bootstrap/Tab"
import Tabs from "react-bootstrap/Tabs"
import Accordion from "react-bootstrap/Accordion"
import {Link} from 'gatsby'
import {PrismicRichText} from '@prismicio/react'
import { scroller } from "react-scroll";
import { hexToRGB } from "../../assets/js/hexToRGB";


// data-layer
import {setProductFlavorClickedEvent, setCategoryViewedEvent, setViewByRangeEvent} from "../../assets/js/data-layer"

// components
import ImgWithFallback from "../ImgWithFallback"

// slice
import PromoModule from "../../slices/new-products/PromoModule"
import {COLOR, LANG} from "../../constants";

const TabsComponent = ({pageData, activeDocMeta}) => {
  const location = useLocation()
  const [key, setKey] = useState("range")
  const [titleGroup, setTitleGroup] = useState(false)
  const [baseURL, setBaseURL] = useState(location?.href)
  const elemRefs = useRef([])
  const {lang} = activeDocMeta ? activeDocMeta : "en-ca"
  const [visitedURL,setVisitedURL]=useState([])
  const [closedAccordion,setClosedAccordion]=useState([])
  const isBrowser = typeof window !== "undefined"

  // console.log('pageData', pageData)

  elemRefs.current = pageData.body1.map((element, i) => {
    return (
        elemRefs.current[i] ?? createRef()
    )
  })

  const handleTitleGroup = (elm) => {
    // console.log('elemCurrentRef ' , elm.current)
    const itemAnchor = `#${elm.current.parentNode.querySelector('.products-anchor').id}`
    const anchorURL = baseURL + itemAnchor
    window.location.href = anchorURL

  }

  // set slice data
  const sliceData = pageData.body2[0]
  // console.log('pageData.body1', pageData.body1)


  useEffect(() => {
    if (!isBrowser) {
      return
    }
    if (isBrowser) {

      if (baseURL.includes('#')) {
        const splitURL = baseURL.split('#')

        // remove utm from pathname
        const splitPathName = splitURL[1].split("?")
        setBaseURL(splitURL[0])
        if (splitPathName[0] === 'refrshr') {
          document.querySelector(`.anchor-toggle_refrshr-lemonade .accordion-button`)?.click()
        } else {
          const accToggleEl = document.querySelector(`.anchor-toggle_${splitPathName[0]} .accordion-button`)

          // if hash doesn't exist
          if(!accToggleEl){
            const currentPage = lang === LANG.EN ?'/products' : "/fr/products"
            navigate(currentPage)
          }
          accToggleEl?.click()
        }
      } else {
        setBaseURL(location.href)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBrowser])

  useEffect(() => {
    if(!isBrowser) {
      return
    }
    if(isBrowser) {
      if(location.href.includes('#')) {
        const splitURL=location.href.split('#')
        // remove utm from pathname
        const splitPathName=splitURL[1].split("?")
        setBaseURL(splitURL[0])
        if(!visitedURL.includes(closedAccordion[closedAccordion.length-1])){
          setClosedAccordion([])
        }
        if(splitPathName[0]==='refrshr') {
          if(!visitedURL.includes(splitPathName[0])) {
            if(closedAccordion.includes(splitPathName[0])) {
              setClosedAccordion((prev) => {
                return [
                  ...prev.filter((x,i) => x!==splitPathName[0])
                ]
              })
              if(visitedURL.length==0){
                setClosedAccordion([])
              }
            }else{
            setVisitedURL(storedURL => [...storedURL,splitPathName[0]])
            document.querySelector(`.anchor-toggle_refrshr-lemonade .accordion-button`)?.click()
            scroller.scrollTo('refrshr',{
              duration: 1000,
              delay: 50,
              smooth: true,
            });
            }
          }
        } else {
          if(!visitedURL.includes(splitPathName[0])) {
            if(closedAccordion.includes(splitPathName[0])) {
              setClosedAccordion((prev) => {
                return [
                  ...prev.filter((x,i) => x!==splitPathName[0])
                ]
              })
              if(visitedURL.length==0){
                setClosedAccordion([])
              }
            }else{
              setVisitedURL(storedURL => [...storedURL,splitPathName[0]])
              document.querySelector(`.anchor-toggle_${splitPathName[0]} .accordion-button`)?.click()
              scroller.scrollTo(`${splitPathName[0]}`,{
                duration: 1000,
                delay: 50,
                smooth: true,
              });
            }
          }
        }
      } else {
        setBaseURL(location.href)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  },[location.href])

  useEffect(() => {
    // data-layer
    setViewByRangeEvent(key)
  }, [key])

  return (
      <div className='w-100' key={key}>
        {/* Prod Page Nav */}
        <Tabs
            id="flavors-accordion"
            activeKey={key}
            key={"tabs"}
            onSelect={(k) => {
              setKey(k)
              // console.log(k)
            }}
            className="products-page-nav"
        >
          <Tab eventKey="viewby" title={lang === 'en-ca' ? "VIEW BY:" : "VER POR:"} key={"tab1"} disabled></Tab>

          <Tab eventKey="range" title={lang === 'en-ca' ? "RANGE" : "GAMA"} key={"tab2"}>
            <Accordion defaultActiveKey={["0"]} alwaysOpen key={"accord-parent-range"}>
              {
                pageData.body1.map((element, index) => {
                  const catNewText = element.primary.accordion_category.document?.data.category_new_text
                  const catItem = element.primary.accordion_category.document?.data.category_item
                  const catItemSubtitle = element.primary.accordion_category.document?.data.category_info_subtitle
                  const includePacks = element.primary.accordion_include_packs
                  const rtbTitles = element.primary.rtb_title.richText
                  const rtbSubTitles = element.primary.rtb_subtitle.richText
                  const headerBgImage = element.primary.accordion_header_bg_image_png?.url || ''
                  const anchorName = element.primary.anchor_name
                  const currentRef = elemRefs.current[index]
                  const headerPromoTitle = element.primary.accordion_header_promo_title.text
                  const headerTitle = element.primary.accordion_header_title.richText
                  const headerBlurb = element.primary.accordion_header_blurb.text
                  const textColor = hexToRGB(element.primary.text_color || COLOR.BLACK)
                  const productImage = {
                    url: element.primary.accordion_header_product_image_png.url,
                    alt: element.primary.accordion_header_product_image_png.alt
                  }
                  const category = element.primary.accordion_header_title.text.toLowerCase()
                  const promoTitle = headerPromoTitle || catNewText

                  const isGradientBg = element.primary.bg_color_type
                  const bgColor = element.primary.bg_color || COLOR.WHITE
                  const gradientFirstColor = element.primary.bg_gradient_first_color || COLOR.WHITE
                  const gradientSecondColor = element.primary.bg_gradient_second_color || COLOR.WHITE

                  const accHeaderStyles = () =>  {

                    if(headerBgImage) {
                      return { backgroundImage: `url(${headerBgImage})`}
                    }

                    if(!headerBgImage && isGradientBg){
                      return { background: `linear-gradient(90deg, ${gradientFirstColor} 10.31%, ${gradientSecondColor} 92.4%)`}
                    }

                    if(!headerBgImage && !isGradientBg){
                      return { backgroundColor: bgColor}
                    }

                  }

                  return (
                      <Accordion.Item eventKey={index} key={"accord-item-range-" + index}
                                      className={`anchor-toggle_${anchorName}`}>

                        <Accordion.Header className="w-100">

                          <div className="row prod-accor-cont" onClick={(elm) => {
                            const item = `${elemRefs.current[index].current.parentNode.querySelector('.products-anchor').id}`
                            if(!visitedURL.includes(item)){
                              setVisitedURL(storedURL=>[...storedURL,item])
                            }
                            handleTitleGroup(currentRef)

                            // data-layer
                            const dl = {category, tab: key}
                            setCategoryViewedEvent(dl)
                          }
                          }>
                            <div className="col-md-12">
                              <div className={"row prod-accor-cont-hd " + element.primary.class_name}
                                   style={accHeaderStyles()}>
                                <div className="col-12 d-flex py-0 px-0 px-0">
                                  <div className="d-flex justify-content-center w-100 position-relative">
                                    <a id={anchorName} className="products-anchor"></a>
                                    {anchorName === 'refrshr-lemonade' &&
                                        <a id='refrshr' className="products-anchor"></a>}
                                    <div
                                        className={"align-self-center position-absolute __title-group-parent " + (titleGroup ? "__active" : "")}
                                        ref={currentRef}>
                                      <div className="d-flex flex-column __title-group">
                                        {promoTitle &&
                                            <span className="align-self-center mb-2 __title-1" style={{color:`${textColor}`,borderColor:`${textColor}`}}>{promoTitle}</span>}
                                        <span className="align-self-center mb-1 __title-2" style={{color:`${textColor}`}}><PrismicRichText
                                            field={headerTitle}/></span>
                                        <span className="align-self-center __title-3" style={{color:`${textColor}`}}>{headerBlurb}</span>
                                      </div>
                                    </div>

                                    <div
                                        className={"prod-accor-cont-hd--img position-absolute " + (index % 2 === 0 ? "position-absolute-right-aligned" : "position-absolute-left-aligned")}>
                                      <ImgWithFallback
                                          classNamePicture={""}
                                          classNameImg={"img-fluid"}
                                          src={productImage.url}
                                          fallbackSrc={productImage.url}
                                          alt={productImage.alt}
                                      />
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </Accordion.Header>

                        <Accordion.Body key={"accord-body-" + index}>

                          <div className="row prod-accor-cont">
                            <div className="col-12">

                              <div className="row accordion-row-body">
                                <div className="col-12 text-center accordion-info-field">
                                  <div className='d-flex justify-content-center flex-wrap'>
                                    {rtbTitles?.length !== 0 && rtbTitles.map((row, index) => {
                                      return <span key={index} className='accordion-info-field__rtb-header-wrap'>
                                                <span
                                                    className="accordion-info-field__rtb-header-text">{row.text}</span>
                                        {rtbSubTitles[index] && <span
                                            className='accordion-info-field__rtb-header-subtext'>{rtbSubTitles[index].text}</span>}
                                              </span>
                                    })}
                                  </div>
                                </div>
                                {catItemSubtitle.richText.length !== 0 &&
                                    <div className="col-12 product-info-subtitle"><PrismicRichText
                                        field={catItemSubtitle.richText}/></div>}
                                {catItem.map((item, index) => {
                                  const catItemURL = item.category_item_link.document?.url
                                  const catItemName = item.category_item_link.document?.data.product_name.richText
                                  const catItemImage = item.category_item_link.document?.data.product_image_png
                                  const catItemNewText = catNewText ? catNewText : item.category_item_link.document?.data.product_new_text
                                  const catItemType = item.category_item_link.document?.data.product_type
                                  const flavor = item.category_item_link.document?.data.taxonomy_product_flavor
                                  const size = item.category_item_link.document?.data.taxonomy_product_size

                                  if (catItemType !== "Pack") {
                                    return (
                                        <div className="col-6 col-md-4 col-lg-3 mb-5" key={"item" + index}>
                                          <div className="d-flex h-100 flex-column align-items-end text-center mx-auto">
                                            <Link
                                                to={catItemURL}
                                                _target="self"
                                                className="mx-auto mt-auto mb-auto align-self-center"
                                                onClick={() => {

                                                  // data-layer
                                                  const dl = {
                                                    flavor,
                                                    category,
                                                    position: index + 1,
                                                    url: catItemURL,
                                                    location: 'products page',
                                                    size
                                                  }
                                                  setProductFlavorClickedEvent(dl)
                                                }}
                                            >
                                              <img
                                                  className={"img-fluid product-image mx-auto"}
                                                  src={catItemImage?.url}
                                                  alt={catItemImage?.alt}
                                              />
                                            </Link>
                                            <span className="mx-auto mt-4 align-self-end product-title">
                                  <span><PrismicRichText field={catItemName}/></span>
                                              {catItemNewText ? <span className="d-block"><span
                                                  className="mx-auto mt-2 align-self-end product-new-item">{catItemNewText}</span></span> : null}
                                </span>
                                          </div>
                                        </div>
                                    )
                                  }
                                })}
                                {catItem.map((item, index) => {
                                  const catItemURL = item.category_item_link.document?.url
                                  const catItemName = item.category_item_link.document?.data.product_name.richText
                                  const catItemImage = item.category_item_link.document?.data.product_image_png
                                  const catItemNewText = catNewText ? catNewText : item.category_item_link.document?.data.product_new_text
                                  const catItemType = item.category_item_link.document?.data.product_type
                                  const flavor = item.category_item_link.document?.data.taxonomy_product_flavor
                                  const size = item.category_item_link.document?.data.taxonomy_product_size

                                  if (includePacks && catItemType === "Pack") {
                                    return (
                                        <div className="col-6 col-md-4 col-lg-3 mb-5" key={"item" + index}>
                                          <div className="d-flex h-100 flex-column align-items-end text-center mx-auto">
                                            <Link
                                                to={catItemURL}
                                                _target="self"
                                                className="mx-auto mt-auto mb-auto align-self-center"
                                                onClick={() => {

                                                  // data-layer
                                                  const dl = {
                                                    flavor,
                                                    category,
                                                    position: index + 1,
                                                    url: catItemURL,
                                                    location: 'products page',
                                                    size
                                                  }
                                                  setProductFlavorClickedEvent(dl)
                                                }}
                                            >
                                              <img
                                                  className={"img-fluid product-image mx-auto"}
                                                  src={catItemImage?.url}
                                                  alt={catItemImage?.alt}
                                              />
                                            </Link>
                                            <span className="mx-auto mt-4 align-self-end product-title">
                                                <span><PrismicRichText field={catItemName}/></span>
                                              {catItemNewText && <span className="d-block"><span
                                                  className="mx-auto mt-2 align-self-end product-new-item">{catItemNewText}</span></span>}
                                              </span>
                                          </div>
                                        </div>
                                    )
                                  }
                                })
                                }
                              </div>

                            </div>
                          </div>

                        </Accordion.Body>
                      </Accordion.Item>
                  )
                })
              }
            </Accordion>
          </Tab>

          <Tab eventKey="new" title={lang === 'en-ca' ? "NEW" : "NOUVEAU"} key={"tab3"}>
            <Accordion defaultActiveKey={["0"]} alwaysOpen key={"accord-parent-new"}>
              {
                pageData.body.map((element, index) => {
                  const catNewText = element.primary.accordion_category.document?.data.category_new_text
                  const catItem = element.primary.accordion_category.document?.data.category_item
                  const includePacks = element.primary.accordion_include_packs
                  const rtbTitles = element.primary.rtb_title.richText
                  const rtbSubTitles = element.primary.rtb_subtitle.richText
                  const headerBgImage = element.primary.accordion_header_bg_image_png?.url || ''
                  const anchorName = element.primary.anchor_name
                  const currentRef = elemRefs.current[index]
                  const headerPromoTitle = element.primary.accordion_header_promo_title.text
                  const headerTitle = element.primary.accordion_header_title.richText
                  const headerBlurb = element.primary.accordion_header_blurb.text
                  const productImage = {
                    url: element.primary.accordion_header_product_image_png.url,
                    alt: element.primary.accordion_header_product_image_png.alt
                  }

                  const promoTitle = headerPromoTitle || catNewText

                  const category = element.primary.accordion_header_title.text.toLowerCase()

                  const isGradientBg = element.primary.bg_color_type
                  const bgColor = element.primary.bg_color || COLOR.WHITE
                  const gradientFirstColor = element.primary.bg_gradient_first_color || COLOR.WHITE
                  const gradientSecondColor = element.primary.bbg_gradient_second_color || COLOR.WHITE

                  const accHeaderStyles = () =>  {


                    if(headerBgImage) {
                      return { backgroundImage: `url(${headerBgImage})`}
                    }

                    if(!headerBgImage && isGradientBg){
                      return { background: `linear-gradient(90deg, ${gradientFirstColor} 10.31%, ${gradientSecondColor} 92.4%)`}
                    }

                    if(!headerBgImage && !isGradientBg){
                      return { backgroundColor: bgColor }
                    }

                  }

                  return (
                      <Accordion.Item eventKey={index} key={"accord-item-new-" + index}
                                      className={`anchor-toggle_${anchorName}`}>

                        <Accordion.Header className="w-100">

                          <div className="row prod-accor-cont" onClick={(elm) => {
                            handleTitleGroup(currentRef)

                            // data-layer
                            const dl = {category, tab: key}
                            setCategoryViewedEvent(dl)
                          }
                          }>
                            <div className="col-md-12">
                              <div className={"row prod-accor-cont-hd " + element.primary.class_name}
                                   style={accHeaderStyles()}>
                                <div className="col-12 d-flex py-0 px-0">
                                  <div className="d-flex justify-content-center w-100 position-relative">
                                    <a id={anchorName} className="products-anchor"></a>
                                    {anchorName === 'refrshr-lemonade' &&
                                        <a id='refrshr' className="products-anchor"></a>}
                                    <div className="align-self-center position-absolute __title-group-parent"
                                         ref={currentRef}>
                                      <div className="d-flex flex-column __title-group">
                                        {promoTitle &&
                                            <span className="align-self-center mb-2 __title-1">{promoTitle}</span>}
                                        <span className="align-self-center mb-1 __title-2"><PrismicRichText
                                            field={headerTitle}/></span>
                                        <span className="align-self-center __title-3">{headerBlurb}</span>
                                      </div>
                                    </div>

                                    <div
                                        className={"prod-accor-cont-hd--img position-absolute " + (index % 2 === 0 ? "position-absolute-right-aligned" : "position-absolute-left-aligned")}>
                                      <ImgWithFallback
                                          classNamePicture={""}
                                          classNameImg={"img-fluid"}
                                          src={productImage.url}
                                          fallbackSrc={productImage.url}
                                          alt={productImage.alt}
                                      />
                                    </div>

                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                        </Accordion.Header>

                        <Accordion.Body key={"accord-body_" + index}>

                          <div className="row prod-accor-cont">
                            <div className="col-12">

                              <div className="row accordion-row-body">
                                <div className="col-12 text-center accordion-info-field">
                                  <div className='d-flex justify-content-center flex-wrap'>
                                    {rtbTitles?.length !== 0 && rtbTitles.map((row, index) => {
                                      return <span key={index} className='accordion-info-field__rtb-header-wrap'>
                                                   <span
                                                       className="accordion-info-field__rtb-header-text">{row.text}</span>
                                        {rtbSubTitles[index] && <span
                                            className='accordion-info-field__rtb-header-subtext'>{rtbSubTitles[index].text}</span>}
                                                </span>
                                    })}
                                  </div>
                                </div>
                                {
                                  catItem.map((item, index) => {
                                    const catItemURL = item.category_item_link.document?.url
                                    const catItemName = item.category_item_link.document?.data.product_name.richText
                                    const catItemImage = item.category_item_link.document?.data.product_image_png
                                    const catItemNewText = catNewText ? catNewText : item.category_item_link.document?.data.product_new_text
                                    const catItemType = item.category_item_link.document?.data.product_type
                                    const flavor = item.category_item_link.document?.data.taxonomy_product_flavor
                                    const size = item.category_item_link.document?.data.taxonomy_product_size

                                    if (catItemType !== "Pack") {
                                      return (
                                          <div className="col-6 col-md-4 col-lg-3 mb-5" key={"item" + index}>
                                            <div
                                                className="d-flex h-100 flex-column align-items-end text-center mx-auto">
                                              <Link
                                                  to={catItemURL}
                                                  _target="self"
                                                  className="mx-auto mt-auto mb-auto align-self-center"
                                                  onClick={() => {

                                                    // data-layer
                                                    const dl = {
                                                      flavor,
                                                      category,
                                                      position: index + 1,
                                                      url: catItemURL,
                                                      location: 'products page',
                                                      size
                                                    }
                                                    setProductFlavorClickedEvent(dl)
                                                  }}
                                              >
                                                <img
                                                    className={"img-fluid product-image mx-auto"}
                                                    src={catItemImage?.url}
                                                    alt={catItemImage?.alt}
                                                />
                                              </Link>
                                              <span className="mx-auto mt-4 align-self-end product-title">
                                                  <span><PrismicRichText field={catItemName}/></span>
                                                {catItemNewText && <span className="d-block"><span
                                                    className="mx-auto mt-2 align-self-end product-new-item">{catItemNewText}</span></span>}
                                                </span>
                                            </div>
                                          </div>
                                      )
                                    }
                                  })}
                                {catItem.map((item, index) => {
                                  const catItemURL = item.category_item_link.document?.url
                                  const catItemName = item.category_item_link.document?.data.product_name.richText
                                  const catItemImage = item.category_item_link.document?.data.product_image_png
                                  const catItemNewText = catNewText ? catNewText : item.category_item_link.document?.data.product_new_text
                                  const catItemType = item.category_item_link.document?.data.product_type
                                  const flavor = item.category_item_link.document?.data.taxonomy_product_flavor
                                  const size = item.category_item_link.document?.data.taxonomy_product_size

                                  if (includePacks && catItemType === "Pack") {
                                    return (
                                        <div className="col-6 col-md-4 col-lg-3 mb-5" key={"item" + index}>
                                          <div className="d-flex h-100 flex-column align-items-end text-center mx-auto">
                                            <Link
                                                to={catItemURL}
                                                _target="self"
                                                className="mx-auto mt-auto mb-auto align-self-center"
                                                onClick={() => {

                                                  // data-layer
                                                  const dl = {
                                                    flavor,
                                                    category,
                                                    position: index + 1,
                                                    url: catItemURL,
                                                    location: 'products page',
                                                    size
                                                  }
                                                  setProductFlavorClickedEvent(dl)
                                                }}
                                            >
                                              <img
                                                  className={"img-fluid product-image mx-auto"}
                                                  src={catItemImage?.url}
                                                  alt={catItemImage?.alt}
                                              />
                                            </Link>
                                            <span className="mx-auto mt-4 align-self-end product-title">
                                                <span><PrismicRichText field={catItemName}/></span>
                                              {catItemNewText && <span className="d-block"><span
                                                  className="mx-auto mt-2 align-self-end product-new-item">{catItemNewText}</span></span>}
                                              </span>
                                          </div>
                                        </div>
                                    )
                                  }
                                })
                                }
                              </div>

                            </div>
                          </div>

                        </Accordion.Body>
                      </Accordion.Item>
                  )
                })
              }
            </Accordion>
          </Tab>
        </Tabs>

        {/* Slice */}
        <PromoModule slice={sliceData}/>
      </div>

  )
}

export default TabsComponent
