module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-prismic-previews/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"wc-ca","accessToken":"MC5aSFVYVnhBQUFDUUFMTllS.HnXvv71vMO-_ve-_ve-_vSI8AO-_vW7vv73vv73vv73vv70277-9AyEtXO-_vVLvv70Y77-977-9F--_ve-_vQ","customTypesApiToken":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0eXBlIjoibWFjaGluZTJtYWNoaW5lIiwiZGJpZCI6IndjLWNhLTAzYjI5MTkyLTk2MTItNGNmNC1iZjA0LTgzNzc1NzQ4Y2ZmN180IiwiZGF0ZSI6MTY4NTM5NTQwNywiZG9tYWluIjoid2MtY2EiLCJpYXQiOjE2ODUzOTU0MDd9.z2avfgekbOpG3PTn4k1Ei_bJc3WjLYNIjMeIHwG_p_Q","promptForAccessToken":true,"apiEndpoint":"https://wc-ca.cdn.prismic.io/api/v2","lang":"*","pageSize":100,"imageImgixParams":{"auto":"compress,format","fit":"max"},"imagePlaceholderImgixParams":{"w":100,"blur":15},"toolbar":"new"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"GTM-PM7D7P7","head":false,"anonymize":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","display":"minimal-ui","icon":"/codebuild/output/src2222725321/src/codestar-connections.us-west-2.amazonaws.com/git-http/603400662265/us-west-2/42625e79-deed-4f1d-8499-8b7906bc0d98/MarkAnthonyGroup/ca.whiteclaw.com/src/assets/images/favicon-icons/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"56bfca77726f8e1b742b5ba5764eb47c"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-PM7D7P7","includeInDevelopment":true,"defaultDataLayer":{"type":"function","value":"function () {\n          return {\n            pageType: window.pageType,\n          }\n        }"},"enableWebVitalsTracking":true,"routeChangeEventName":"gatsby-route-change","selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
